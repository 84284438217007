import Carousel from 'react-bootstrap/Carousel';
import Bharat from './Bharat net.webp'
import BSNL from './BSNL-.webp'
import Air from './BSNL Air Filter.jpg'
// import './Style.css'
function ImageSlide() {
  return (
     <Carousel className='col-sm-12 col-md-12 slider'>
      <Carousel.Item>
        <img 
          className="d-block w-100"
          src={Bharat}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={BSNL}
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item >
        <img
          className="d-block w-100"
          src={Air}
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default ImageSlide;