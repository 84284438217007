import React from "react";
import office from './office.jpg'
// import './Style.css'
function About() {
    return (
        <div className="container-fluid ">
            <div className="row about-div">
                <div className="col-sm-4 col-md-4 about">
                    <h1>About Chaudhary Communicator</h1>
                    <p>Chaudhary Communicator opened its doors in 2000, and has been providing great services ever since. What sets us apart from the rest is our ability to customize our offering to customers’ needs, as well as our fantastic team of specialists.
                        <br/><br/>
                        No matter how unique or challenging your request is, you can count on us to find the right solution for you. Browse through our products and services below, and get in touch with us today.</p>
                </div>
                <div className="col-sm-4 col-md-6 about-img">
                    <img src={office}
                        alt="office" />
                </div>
            </div>
        </div>
    )
}

export default About