import React from "react";
function Contact() {
    return (
        <div className="contact">
            <h1>Contact US</h1>
            <div className="row details">
                <div className="col-sm-4 col-md-4">
                <h2>Address</h2>
                    <h6>Ram Janki Mandir Road <br />
                        Ladania, Bihar<br />
                        847232</h6>
                </div>
                <div className="col-sm-4 col-md-4">
                    <h2>Contact</h2>
                    <h6>
                        +91-9470871001<br />

                        +91-9470218777<br />

                        06246-295777<br />

                        06246-275257<br /><br />

                        chaudhary.communicator@ymail.com

                        chaudhary.communicator@rediffmail.com
                    </h6>
                </div>
                <div className="col-sm-4 col-md-4">
                    <h2>Visiting Hours</h2>

                    <h6>Sun-Sat 8:00 am – 8:00 pm</h6>

                </div>
            </div>
        </div>
    )
}

export default Contact