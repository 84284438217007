import wifi from './wifi.jpeg'
import './Style.css'
function Header() {
  return (
    <div>
      <div className='row'>
        <div className='col-sm-12 col-md-12 header'>
        <img
              alt=""
              src={wifi}
              width="130"
              height="90"
              className="d-inline-block align-top"
            /><label style={{color:'#4682B4', fontSize:70, fontWeight: 'bold', paddingLeft:'20px'}}>Chaudhary Communicator</label>
        </div>
      </div>
    </div>
  );
}

export default Header;