import React from "react";
import Bharat from './Bharat net.webp'
import BSNL from './BSNL-.webp'
import Air from './BSNL Air Filter.jpg'
import Leased from './leased-line.webp'
import Vpn from './VPN.webp'
import Router from './Router.jpg'
// import './Style.css'
function Service() {
    return (
        <div className="container-fluid service">
            <div className="row">
                <h1>Services</h1>
                <div className="col-sm-4 col-md-4">
                    <img src={BSNL} alt="bsnl" height="150px" width="250px"/>
                    <h4>BSNL Fiber</h4>
                </div>
                <div className="col-sm-4 col-md-4">
                    <img src={Bharat}
                        alt="office" height="150px" width="250px"/>
                        <h4>Bharat Net</h4>
                </div>
                <div className="col-sm-4 col-md-4">
                    <img src={Air}
                        alt="office" height="150px" width="250px" />
                        <h4>BSNL Air Filter</h4>
                </div>
                <div className="col-sm-4 col-md-4">
                    <img src={Leased}
                        alt="office" height="150px" width="250px" />
                        <h4>Leased Line</h4>
                </div>
                <div className="col-sm-4 col-md-4">
                    <img src={Vpn}
                        alt="office" height="150px" width="250px" />
                        <h4>VPN</h4>
                </div>
                <div className="col-sm-4 col-md-4">
                    <img src={Router}
                        alt="office" height="150px" width="250px" />
                        <h4>Router/Broadband</h4>
                </div>
                <h5>These are some Internet services we provide to our customers, also connect us for new Internet connection or for new Router or Broadband</h5>
            </div>
        </div>
    )
}

export default Service