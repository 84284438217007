// import logo from './logo.svg';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import './App.css';
import About from './container/About';
import Header from './container/Header';
import ImageSlide from './container/ImageSlide';
import Service from './container/Service';
import Contact from './container/Contact';
import Map from './container/Map';
function App() {
  return (
    <div className="App">
      <Header />
      <ImageSlide />
      <About />
      <Service />
      <Contact />
      <Map />
    </div>
  );
}

export default App;
