import React from "react";
import Iframe from 'react-iframe'
function Map() {
    return (
        <div className="map" >
            <Iframe className="col-sm-12 d-block w-100 mapFrame" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3567.0143213807746!2d86.32351349999999!3d26.615987699999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ee9c6100000001%3A0x3276a136a7ae0223!2sChaudhary%20Communicator!5e0!3m2!1sen!2sin!4v1677843721430!5m2!1sen!2sin" frameborder="10"
                 allowfullscreen></Iframe>
        </div>
      )
}

export default Map